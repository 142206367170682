$(document).ready(function(){
    Lang.setLocale($('html').attr('lang'));

    $('#mobile-menu').on('click', function(){
		$(this).toggleClass('open');
		$('#top nav').slideToggle();
	})

	$('.button.search-options').on('click', function(e){
		e.preventDefault();
		$(this).toggleClass('state-open');
		$('#form-search-detailed').slideToggle();
	});

	$('.button.toggle-comment').on('click', function(e){
		e.preventDefault();
		$(this).toggleClass('open');
		$('.product-info.comments').toggleClass('open');
	});

	$('.product-thumbnails li .thumbbox').on('click', function(){
		$('.product-thumbnails li .thumbbox').removeClass('active');
		$(this).addClass('active');


		thumbUrl = $(this).find('img').data('big');
        fullUrl = $(this).find('img').data('image');

		$('.product .product-main-image a').attr('href', fullUrl);
		$('.product .product-main-image img').attr('src', thumbUrl);
	})

    $('.remember-advert').on('click', function(e){
        e.preventDefault();
        $.ajax({
            dataType: "json",
            url: $(this).data('url'),
            data: {advert_id: $(this).data('advert')},
            success: function(result){
                $('.remembered-count').text(result.count);
            }
        });
        $(this).toggleClass('remembered');
    });

    // Tabs
    $('.user-tabs .list-tabs-content').each(function(i, el) {
        $(el).attr('id', 'tab-content-' + (i + 1));
    });

    $('.user-tabs .list-tabs-content').first().show();

    $('.user-tabs .list-tabs li').each(function(i, el) {
        $(el).attr('tab', i + 1);
    });

    $('.user-tabs .list-tabs').on('click', 'li:not(".active")', function() {
        $this = $(this);
        $('.user-tabs .list-tabs .active').removeClass('active');
        $(this).addClass('active');

        $('.user-tabs .list-tabs-content:visible').hide();
        $('#tab-content-' + $this.attr('tab')).show();

    });

    var data = [];
    $('.product-thumbnails img').each(function(){
        data.push({
            href: $(this).data('image')
        });
    });
    $('#start-lightbox').click(function(event){
        event.preventDefault();
        var list = $('.thumbbox');
        var item = $('.thumbbox.active');
        var thumbIndex = list.index(item);
        $.fancybox(data, {
            index: thumbIndex
        });
    });


    // search fields by category
    $('#category_selector').on('change', function(){
        var category_id = $(this).val();
        var slug = $(this).find(':selected').data('slug');

        var $form = $(this).closest('form');

        if(category_id > 0){
            if($(this).data('set_form')) {
                $form.attr('action', $form.data('url') + '/' + slug);
            }
            $.get(
                laroute.route('open.ajax.home.search_fields'),
                {
                    category_id: category_id,
                    type: $(this).data('type')
                },
                function( data ) {
                $('#features_fields').html(data);
            });
        } else {
            if($(this).data('set_form')) {
                $form.attr('action', $form.data('url'));
            }
            $('#features_fields').html('');
        }
    });

    if($('#category_selector').val() > 0){
        var category_id = $('#category_selector').val();
        if($('#features_fields').text().trim().length < 1){
            $.get(laroute.route('open.ajax.home.search_fields'), {category_id: category_id}, function( data ) {
                $('#features_fields').html(data);
            });
        }
    }

    $(document).on('keyup change', '.input-group input', function(){
        var $parent = $(this).parent('.input-group')
        var index = $parent.data('index');
        var $inputs = $parent.find('input');
        $inputs[1].value = ($inputs[0].value * index).toPrecision(3);
    });

    $('.input-group').each(function(){
        var $parent = $(this);
        var index = $parent.data('index');
        var $inputs = $(this).find('input');
        if($inputs.length > 1){
            $inputs[1].value = ($inputs[0].value * index).toPrecision(3);
        }
    });


});

$(window).on("load", function() {
    $('#main-content').css('min-height', $('#ads').height());
});
//
//function setContentHeight(){
//    var $images = $('#ads img');
//    var loaded_images_count = 0;
//
//    $images.load(function(){
//        loaded_images_count++;
//
//        if (loaded_images_count == $images.length) {
//            $('#main-content').css('min-height', $('#ads').height());
//        }
//    });
//}